import { documents as sidDocuments } from '../../../../../store/modules/securitize-id/options';

const documents = {
    ...sidDocuments,
    getFields: isConfidential => [
    {
      key: 'thumbnail',
      label: 'Preview',
    },
    !isConfidential && {
      key: 'docCategory',
      label: 'Document Category',
      tdClass: 'align-middle',
    },
    {
      key: 'docType',
      label: 'Document Type',
      tdClass: 'align-middle',
    },
    {
      key: 'createDate',
      label: 'Creation Date',
      tdClass: 'align-middle',
    },
    {
      key: 'validUntil',
      label: 'Expired On',
      tdClass: 'align-middle',
    },
    !isConfidential && {
      key: 'status',
      label: 'status',
      tdClass: 'align-middle',
    },
    {
      key: 'actions',
      label: ' ',
      tdClass: 'text-nowrap d-inline float-right',
    },
  ],
  statuses: [
    {
      text: 'Pending',
      value: 'pending',
    },
    {
      text: 'Verified',
      value: 'verified',
    },
    {
      text: 'Not verified',
      value: 'not-verified',
    },
  ],
};

export default documents;
