<template>
  <div
    class="card mb-5"
    :class="isLoading?'sec-saving':''"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            General information
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="isEditButtonDisabled"
          @changeMode="changeModeProxy"
          @update="saveChanges"
        />
      </div>
    </div>
    <div class="card-body">
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
        :default-value="getGeneralInfo"
      >
        <div class="mb-4 bg-light full-width">
          <div class="col-md-6 d-flex investor-type-field">
            <span>Investor Type: </span>
            <cp-select
              v-if="isDetailsEditableCurrentBlock && isIndividual"
              key="isHidden"
              :options="optionsList.individualInvestorTypes"
              name="isHidden"
            />
            <strong
              v-else
              class="investor-type-value"
            >
              {{ getGeneralInfo.isHidden ? 'Individual Without an Account' : getGeneralInfo.investorType }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isEntity">
                  <td>Name:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="name"
                      name="name"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.name | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--Name-->
                <tr v-if="isEntity">
                  <td>Entity DBA:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityDba"
                      name="entityDba"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.entityDba | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--entityDba-->
                <tr v-if="isIndividual">
                  <td>First Name:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="firstName"
                      name="firstName"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.firstName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--firstName-->
                <tr v-if="isIndividual">
                  <td>Middle Name:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="middleName"
                      name="middleName"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.middleName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--middleName-->
                <tr v-if="isIndividual">
                  <td>Last Name:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="lastName"
                      name="lastName"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.lastName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--lastName-->
                <tr>
                  <td>Identifier Email:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.identifierEmail }}
                    </strong>
                  </td>
                </tr><!--email-->
                <tr v-if="isIndividual">
                  <td>User Credentials:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="userEmail"
                      name="userEmail"
                      validate="required|email"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.userEmail }}
                    </strong>
                  </td>
                </tr><!--email-->
                <tr>
                  <td>Contact Email:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="contactEmail"
                      name="contactEmail"
                      validate="required|email"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.contactEmail }}
                    </strong>
                  </td>
                </tr><!--contactEmail-->
                <tr>
                  <td>Risk Level:</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="riskLevel"
                      :options="optionsList.riskLevelTypesList"
                      name="riskLevel"
                    />
                    <strong
                      v-else
                      class="text-capitalize"
                    >
                      {{ getGeneralInfo.riskLevel | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--riskLevel-->
                <tr v-if="isEntity">
                  <td>Type:</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityType"
                      :options="optionsList.entityTypesList"
                      name="entityType"
                    />
                    <strong v-else>
                      {{ $t(`securitizeId.entityType["${getGeneralInfo.entityType}"]`) || getGeneralInfo.entityType }}
                    </strong>
                  </td>
                </tr><!--entityType-->
                <tr v-if="isIndividual">
                  <td>Phone Prefix:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="phonePrefix"
                      name="phonePrefix"
                      placeholder="e.g +1"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.phonePrefix | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--phonePrefix-->
                <tr v-if="isIndividual">
                  <td>Phone:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="phoneNumber"
                      name="phoneNumber"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.phoneNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--phoneNumber-->
                <tr v-if="isIndividual">
                  <td>Birthdate</td>
                  <td>
                    <cp-date-picker
                      v-if="isDetailsEditableCurrentBlock"
                      key="birthDay"
                      name="birthDay"
                      initial-view="year"
                      :open-date="openDate"
                      :disabled-dates="disabledDates"
                      server-format="YYYY-MM-DD"
                      use-utc
                    />
                    <strong v-else>
                      <template v-if="getGeneralInfo.birthDay">
                        {{ getGeneralInfo.birthDay | dateFilterWOTz }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </strong>
                  </td>
                </tr><!--birthdate-->
                <tr v-if="isIndividual">
                  <td>Gender:</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="gender"
                      :options="optionsList.genderTypesList"
                      name="gender"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.gender | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--gender-->
                <tr>
                  <td>
                    Country:
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="countryCode"
                      :options="countries"
                      name="countryCode"
                      validate="required"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('countryCode') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--countryCode-->
                <tr v-if="model.countryCode === 'US'">
                  <td>
                    State:
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="state"
                      v-model="model.state"
                      :options="stateOptions"
                      name="state"
                    />
                    <strong v-else>
                      {{ generalDetailState | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--state-->
                <tr>
                  <td>City:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="city"
                      name="city"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.city | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--city-->
                <tr>
                  <td>Postal Code:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="postalCode"
                      name="postalCode"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.postalCode | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--postalCode-->
                <tr>
                  <td>Address:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="street"
                      name="street"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.street | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--Address-->
                <tr>
                  <td>Additional Info:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="addressAdditionalInfo"
                      name="addressAdditionalInfo"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.addressAdditionalInfo | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--addressAdditionalInfo-->
                <tr>
                  <td>Annual Income:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.annualIncome | parseFromTo }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isEntity">
                  <td>Tax ID:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityIdNumber"
                      name="entityIdNumber"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.entityIdNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId-->
                <tr v-if="isEntity">
                  <td>Tax Country (FATCA):</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="countryCode"
                      :options="countries"
                      name="countryCode"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('countryCode') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode-->
                <tr v-if="isIndividual">
                  <td>Tax ID:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxId1"
                      name="taxId1"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.taxId1 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId1-->
                <tr v-if="isIndividual">
                  <td>Tax Country 1 (FATCA):</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode1"
                      :options="countries"
                      name="taxCountryCode1"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('taxCountryCode1') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode1-->
                <tr v-if="isIndividual">
                  <td>Tax ID 2:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxId2"
                      name="taxId2"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.taxId2 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId2-->
                <tr v-if="isIndividual">
                  <td>Tax Country 2 (FATCA):</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode2"
                      :options="countries"
                      name="taxCountryCode2"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('taxCountryCode2') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode2-->
                <tr v-if="isIndividual">
                  <td>Tax ID 3:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxId3"
                      name="taxId3"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.taxId3 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId3-->
                <tr v-if="isIndividual">
                  <td>Tax Country 3 (FATCA):</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode3"
                      :options="countries"
                      name="taxCountryCode3"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('taxCountryCode3') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode3-->
                <tr v-if="isIndividual">
                  <td>Identity Document Number:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="identityDocumentNumber"
                      name="identityDocumentNumber"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.identityDocumentNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--Identity Document Number-->
                <tr v-if="isIndividual">
                  <td>Country of Birth:</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="countryOfBirth"
                      :options="countries"
                      name="countryOfBirth"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('countryOfBirth') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--countryOfBirth-->
                <tr v-if="isIndividual">
                  <td>City of Birth:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="cityOfBirth"
                      name="cityOfBirth"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.cityOfBirth | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--cityOfBirth-->
                <tr>
                  <td>Creation Date:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.creationDate | dateFilter }}
                    </strong>
                  </td>
                </tr><!--creationDate-->
                <tr>
                  <td>
                    Issuers:
                  </td>
                  <td>
                    <span
                      v-for="(data, index) of getGeneralInfo.issuersData"
                      :key="index"
                    >
                      <router-link
                        target="_blank"
                        :to="{name: 'investor-details-mfe', params: {idIssuer: data.issuerId, tokenId: data.tokenId, detailsId: data.userId}}"
                      >
                        {{ data.issuerName }}
                      </router-link>
                      {{ index !== getGeneralInfo.issuersData.length-1 ? ',' : '' }}
                    </span>
                    <strong v-if="withoutIssuers">
                      -
                    </strong>
                  </td>
                </tr><!-- accreditationStatus-->
                <tr>
                  <td>Accreditation Status:</td>
                  <td>
                    <strong
                      class="text-capitalize"
                    >
                      {{ getGeneralInfo.accreditationStatus || '-' }}
                    </strong>
                  </td>
                </tr><!--accreditationStatus-->
                <tr v-if="isEntity">
                  <td>Incorporation Date:</td>
                  <td>
                    <cp-date-picker
                      v-if="isDetailsEditableCurrentBlock"
                      name="incorporationDate"
                      initial-view="year"
                    />
                    <strong v-else>
                      {{ (getGeneralInfo.incorporationDate || null) | dateFilterWOTz | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--incorporationDate-->
                <tr v-if="isEntity">
                  <td>Line of Business:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityLineOfBusiness"
                      name="entityLineOfBusiness"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.entityLineOfBusiness | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--entityLineOfBusiness-->
                <tr v-if="isEntity">
                  <td>Source of Funds:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entitySourceOfFunds"
                      name="entitySourceOfFunds"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.entitySourceOfFunds | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--entitySourceOfFunds-->
                <tr
                  v-if="isIndividual"
                >
                  <td>Authorized By:</td>
                  <td>
                    <span
                      v-for="(authAccount, index) in authorizedByAccounts"
                      :key="index"
                    >
                      <router-link
                        target="_blank"
                        :to="{name: 'securitizeIdInvestor', params: {investorId: authAccount.investorId}}"
                      >
                        {{ authAccount.displayName }}
                      </router-link>
                      {{ (index !== authorizedByAccounts.length-1) ? ',' : '' }}
                    </span>
                    <strong v-if="!authorizedByAccounts.length">-</strong>
                  </td>
                </tr><!--authorizedByAccounts-->
                <tr>
                  <td>LTID:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="largeTraderId"
                      name="largeTraderId"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.largeTraderId | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--largeTraderId-->
                <tr v-if="isEntity">
                  <td>LEI Code:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityLeiCode"
                      name="entityLeiCode"
                      :max-length="20"
                      validate="alphanumeric|min:20"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.entityLeiCode | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--entityLeiCode-->
                <tr v-if="isEntity">
                  <td>Website:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityWebsite"
                      name="entityWebsite"
                      validate="website_url"
                    />
                    <strong v-else>
                      {{ getGeneralInfo.entityWebsite | emptyToNA | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--entityWebsite-->
                <tr>
                  <td>Net Worth:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.netWorth | parseFromTo }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <cp-comments-log
          v-if="doDisplayComments"
          v-model="model.comment"
          :is-editable="isDetailsEditableCurrentBlock"
          :comments-array="commentsArray"
        />
      </cp-input-container>
    </div>
    <cp-confirm-modal
      ref="cpConfirmModal"
      title="Change investor type"
      ok-only
      @onOk="$emit('confirm')"
    >
      Note that when changing the investor type, some of the information might be lost
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import states from '@/utilities/us-states-list';
import { countriesList as countries, optionsList } from '~/pages/securitize-id/components/options';
import { CpDatePicker, CpSelect, CpInput } from '@/components/common/standalone-components/inputs';
import CpCommentsLog from '~/components/common/standalone-components/cp-comments-log';
import cpInputContainer from '@/components/common/cpInputContainer';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpEditButtons from '~/components/common/edit-buttons';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';


export default {
  name: 'SecuritizeIdGeneralInformation',
  components: {
    CpEditButtons,
    cpInputContainer,
    CpDatePicker,
    CpCommentsLog,
    CpSelect,
    CpInput,
    CpConfirmModal,
  },
  filters: {
    parseFromTo: (val) => {
      if (!val || !val.value) {
        return '-';
      }
      return `$${val.value}`;
    },
  },
  mixins: [
    CpEditableMixin('general', 'securitizeId'),
  ],
  data() {
    return {
      isLoading: false,
      states,
      countries,
      optionsList,
      disabledDates: {
        from: new Date(moment()
          .subtract(15, 'years')),
      },
      openDate: new Date('1990-01-01'),
      model: {},
      authorizedByAccounts: [],
    };
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    ...mapGetters('currentOperator', ['operatorData']),
    stateOptions() {
      return Object.keys(this.states)
        .map(val => ({ text: this.states[val], value: val }));
    },
    generalDetailState() {
      const currentState = this.stateOptions.find(el => el.value === this.model.state);
      return currentState ? currentState.text : null;
    },
    isIndividual() {
      return this.model.investorType === 'individual';
    },
    isEntity() {
      return this.model.investorType === 'entity';
    },
    commentsArray() {
      return [...(this.getGeneralInfo.comments || [])].reverse().map(comment => comment.split('\n')).flat();
    },
    withoutIssuers() {
      return !this.getGeneralInfo.issuersData.length;
    },
    doDisplayComments() {
      return (this.getGeneralInfo && this.getGeneralInfo.comments && !!this.getGeneralInfo.comments.length) || this.isDetailsEditableCurrentBlock;
    },
  },
  created() {
    this.changeMode(true);
    this.model = { ...this.getGeneralInfo };

    if (this.isEntity) return;
    this.getSecuritizeIdAuthorizedByAccounts({ investorId: this.getGeneralInfo.investorId })
      .then(({ data }) => {
        this.authorizedByAccounts = data.filter(authAcc => authAcc.status === 'approved')
        .map((authAcc) => {
          authAcc.displayName = authAcc.displayName || authAcc.email;
          return authAcc;
        })
        .sort((a, b) => {
          const textA = a.displayName.toUpperCase();
          const textB = b.displayName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      });
  },
  methods: {
    ...mapActions('securitizeId', ['updateSecuritizeIdInvestorByInvestorId', 'removeSecuritizeIdInvestor2fa', 'getSecuritizeIdInvestorByInvestorId', 'securitizeIdInvestorResetPassword', 'getSecuritizeIdAuthorizedByAccounts']),
    changeModeProxy() {
      this.model = {
        ...this.getGeneralInfo,
        comment: '',
      };
      this.changeMode();
    },
    getCurrentCountryName(field) {
      const country = this.countries.find(item => item.value === this.getGeneralInfo[field]);
      return country ? country.text : null;
    },
    saveChanges() {
      if (this.model.countryCode !== 'US') this.model.state = undefined;
      const info = { comments: [...this.getGeneralInfo.comments || []] };

      this.$refs.cpInputContainer.validateForm()
        .then(() => {
          if (this.$refs.cpInputContainer.isValid()) {
            const timestamp = new Date().toISOString();
            const commentInput = this.model.comment.trim().includes('\n') ? `\n${this.model.comment.trim()}` : this.model.comment.trim();

            if (commentInput) {
              const newComment = `${this.operatorName()} at ${moment(timestamp).format('hh:mm MMM D,YYYY')}: ${commentInput}`;
              info.lastCommentBy = this.operatorName();
              info.lastCommentTime = timestamp;
              info.comments.push(newComment);
            }
            if (this.model.incorporationDate) {
              info.incorporationDate = new Date(this.model.incorporationDate).toISOString();
            }

            this.isLoading = true;
            this.updateSecuritizeIdInvestorByInvestorId({
              investorId: this.getGeneralInfo.investorId,
              info: { ...this.model, ...info },
            })
              .then(() => {
                this.changeMode();
                this.getSecuritizeIdInvestorByInvestorId({
                  investorId: this.getGeneralInfo.investorId,
                })
                  .then(() => {
                    this.isLoading = false;
                    this.model = { ...this.getGeneralInfo };
                  });
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        });
    },
    callConfirmModal() {
      this.$refs.cpConfirmModal.show();
    },
    formatDate(date) {
      return moment(date).format('HH:mm DD-MM-YYYY');
    },
    operatorName() {
      return this.operatorData.viewData.getName ? this.operatorData.viewData.getName() : this.operatorData.name;
    },
  },
};
</script>
<style scoped lang="scss">
  .investor-type-field {
    padding: 10px 24px;
    align-items: baseline;
    span {
      margin-right: 5px;
    }
    div {
      width: 76%;
    }
  }
  .full-width {
    margin: -24px;
  }
  .investor-type-value {
    text-transform: capitalize;
  }
  .sec-saving::after{
      content:"";
      position: absolute;
      width:100%;
      height:100%;
      background: #fff;
      opacity: 0.4;
    }
</style>
