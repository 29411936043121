<template>
  <div class="card mb-5">
    <!--Documents header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="mb-0 text-primary">
            {{ isConfidential ? 'Confidential Documents' : 'Documents' }}
          </h4>
          <cp-edit-buttons
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="saveChanges"
          >
            <template slot="leftToButtons">
              <cp-button
                v-if="!isConfidential"
                icon="ios-arrow-round-down"
                variant="outline-primary"
                class="mr-2"
                :disabled="downloadingDocuments.all"
                :is-loading="downloadingDocuments.all"
                @click="downloadAllDocument"
              >
                Download all documents
              </cp-button>
              <cp-button
                class="mr-2"
                icon="ios-add"
                variant="primary"
                @click="showAddDocModal"
              >
                Add document
              </cp-button>
            </template>
          </cp-edit-buttons>
        </div>
      </div>
    </div>

    <!--Documents table-->
    <cp-table
      ref="cpTable"
      without-search
      without-pagination
      :get-data-action="`securitizeId/${isConfidential ?
        'getSecuritizeIdInvestorConfidentialDocuments' :
        'getSecuritizeIdInvestorDocuments'}`"
      :url-params="urlParams"
      :fields="documentsFields"
      :empty-table-message="emptyTableMessage"
    >
      <template
        slot="thumbnail"
        slot-scope="{ rowData }"
      >
        <img
          :ref="rowData.item.documentId"
          class="ui-w-40 d-block link"
          :src="getImageIcon(rowData.item)"
          style="cursor: pointer"
          alt="Document preview"
          @click="showPreviewDocModal(rowData.item)"
          @error="showFallbackImage(rowData.item)"
        >
      </template>
      <template
        slot="docType"
        slot-scope="{ rowData }"
      >
        <span v-if="!editMode">
          {{ getDocTypeName(rowData.item.docCategory, rowData.item.docType) }}
        </span>
        <cp-select
          v-else
          v-model="model[rowData.index].docType"
          :options="getDocumentTypesList()"
        />
      </template>
      <template
        slot="createDate"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.createDate | dateFilter }}
      </template>
      <template
        slot="validUntil"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.validUntil | nullFilter | dateFilter }}
      </template>
      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge
          v-if="!editMode"
          :class="getStatusColor(rowData.item)"
        >
          {{ rowData.item.verificationStatus }}
        </b-badge>
        <cp-select
          v-else
          v-model="model[rowData.index].verificationStatus"
          :options="statusOptions"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            icon="ios-arrow-round-down"
            variant="outline-primary"
            :disabled="downloadingDocuments[rowData.item.documentId]"
            :is-loading="downloadingDocuments[rowData.item.documentId]"
            @click="downloadDocument(rowData.item)"
          >
            Download
          </cp-button>
          <cp-button
            icon="ion ion-ios-trash"
            variant="default ml-3"
            @click="showDeleteModal(rowData.item)"
          >
            Delete
          </cp-button>
        </div>
      </template>
    </cp-table>

    <!--modals-->

    <add-document-modal
      ref="addDocModal"
      :categories="categories"
      :is-confidential="isConfidential"
      @docIsAdded="refreshTable"
    />

    <preview-document-modal
      ref="previewDocModal"
      :document-id="documentId"
      :investor-id="urlParams.investorId"
      :file-type="fileType"
      :is-confidential="isConfidential"
    />

    <cp-confirm-modal
      ref="cpConfirmModal"
      title="Delete document"
      :is-confidential="isConfidential"
      @onOk="deleteDocument"
    >
      {{ confirmModalText }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Cookie from 'js-cookie';
import JSZip from 'jszip';
import documents from './options';
import AddDocumentModal from './modals/add-document-modal';
import PreviewDocumentModal from './modals/preview-document-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpTable from '~/components/shared/cp-table';
import staticPathGeneration from '~/mixins/static-path-generation';
import CpEditableMixin from '~/mixins/editable-component';
import CpEditButtons from '@/components/common/edit-buttons';
import { CpSelect } from '@/components/common/standalone-components/inputs';

export default {
  name: 'SecuritizeIdInvestorDocuments',
  components: {
    CpButton,
    CpConfirmModal,
    AddDocumentModal,
    PreviewDocumentModal,
    CpTable,
    CpEditButtons,
    CpSelect,
  },
  mixins: [staticPathGeneration, CpEditableMixin],
  props: {
    isConfidential: Boolean,
    refreshDocTable: Boolean,
    categories: {
      type: Object,
      required: true,
    },
  },
  data() {
    Cookie.set('perPage', 100);
    return {
      fileType: '',
      documentId: null,
      downloadingDocuments: { all: false },
      deleteItem: null,
      confirmModalText: 'Are you sure you want to delete this document?',
      emptyTableMessage: 'There are no documents. Click on ‘Add Document’ to upload a new one',
      model: [],
      statusOptions: documents.statuses,
    };
  },
  computed: {
    ...mapGetters('securitizeId', ['getGeneralInfo']),
    urlParams() {
      return { investorId: this.getGeneralInfo.investorId };
    },
    documentsFields() {
      return documents.getFields(this.isConfidential, this.getGeneralInfo.investorType);
    },
  },
  watch: {
    refreshDocTable() {
      this.refreshTable();
    },
  },
  methods: {
    ...mapActions('securitizeId', [
      'downloadSecuritizeIdInvestorDocumentById',
      'downloadSecuritizeIdDocumentBlobByKey',
      'deleteSecuritizeIdInvestorDocumentById',
      'getSecuritizeIdInvestorConfidentialDocuments',
      'updateInvestorDocuments',
      'updateInvestorConfidentialDocuments',
      'getSecuritizeIdInvestorDocuments',
    ]),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    refreshTable() {
      this.$refs.cpTable.updateTableData();
    },
    getImageIcon({ thumbnail, fileType }) {
      return fileType === 'application/pdf'
        ? this.getImagePath('fallback-pdf.jpg')
        : thumbnail || this.getImagePath('fallback-image.jpg');
    },
    showFallbackImage({ documentId }) {
      this.$refs[documentId].src = this.getImagePath('fallback-image.jpg');
    },
    showDeleteModal({ documentId }) {
      this.deleteItem = documentId;
      this.$refs.cpConfirmModal.show();
    },
    showPreviewDocModal({ documentId, fileType }) {
      this.documentId = documentId;
      this.fileType = fileType;
      this.$refs.previewDocModal.showPreview();
    },
    showAddDocModal() {
      this.$refs.addDocModal.show();
    },
    deleteDocument() {
      this.deleteSecuritizeIdInvestorDocumentById({
        ...this.urlParams,
        documentId: this.deleteItem,
        isConfidential: this.isConfidential,
      }).then(this.refreshTable);
    },
    getStatusColor(document) {
      let statusColor = 'badge-outline-dark';
      switch (document.verificationStatus) {
        case 'pending':
          statusColor = 'badge-outline-primary';
          break;
        case 'not-verified':
          statusColor = 'badge-outline-danger';
          break;
        case 'manual-review':
          statusColor = 'badge-outline-dark';
          break;
        case 'verified':
          statusColor = 'badge-outline-success';
          break;
        default:
      }
      return `${statusColor}`;
    },
    downloadDocument({ documentId }) {
      this.downloadingDocuments[documentId] = true;
      this.downloadSecuritizeIdInvestorDocumentById({
        ...this.urlParams,
        documentId,
        isConfidential: this.isConfidential,
      }).then(
        ({ data: { url } }) => {
          if (url) {
            // to easy download file, create downloadable link and click it
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = url.split('/').pop();
            link.href = url;
            link.click();
          } else {
            this.CALL_ERROR_TOASTER('Failed to fetch file from server');
          }
        },
      ).finally(() => {
        delete this.downloadingDocuments[documentId];
      });
    },
    async downloadAllDocument() {
      this.downloadingDocuments.all = true;

      const { data: { data: investorDocuments } } = await this.getSecuritizeIdInvestorDocuments({ params: { investorId: this.getGeneralInfo.investorId } });
      if (!investorDocuments?.length) {
        this.downloadingDocuments.all = false;
        return;
      }

      const documentsBlobs = await Promise.all(
        investorDocuments.map(({ fileName }) => this.downloadSecuritizeIdDocumentBlobByKey({ investorId: this.getGeneralInfo.investorId, key: fileName })),
      );

      const zip = new JSZip();
      const sameDocumentTypeCountersHash = investorDocuments.reduce((acc, { docType }) => ({ ...acc, [docType]: Array.from({ length: investorDocuments.filter(doc => doc.docType === docType)?.length }, (_, i) => i) }), {});
      documentsBlobs.forEach((documentBlob, i) => {
        const { fileType, docType } = investorDocuments[i];
        const documentName = sameDocumentTypeCountersHash[docType][0]
          ? `${docType}(${sameDocumentTypeCountersHash[docType]?.shift()})`
          : `${docType}${sameDocumentTypeCountersHash[docType]?.shift() ? '' : ''}`;

        zip.file(`${documentName}.${fileType.split('/')?.[1]}`, documentBlob);
      });

      const zipName = this.getGeneralInfo.investorType === 'entity'
        ? this.getGeneralInfo.fullName || this.getGeneralInfo.name
        : this.getGeneralInfo.fullName || `${this.getGeneralInfo.firstName} ${this.getGeneralInfo.lastName}`;

      zip.generateAsync({ type: 'blob' }).then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${zipName}.zip`;
        link.click();
        URL.revokeObjectURL(url);
      });

      this.downloadingDocuments.all = false;
    },
    changeModeProxy() {
      this.model = this.$refs.cpTable.getTableData()
        .map(({ documentId, verificationStatus, docType }) => ({ documentId, verificationStatus, docType }));
      this.changeMode();
    },
    saveChanges() {
      const tableData = this.$refs.cpTable.getTableData();
      const documentsData = this.model.reduce((acc, item, index) => {
        const docToUpdate = { ...item };
        if (docToUpdate.verificationStatus === tableData[index].verificationStatus) {
          delete docToUpdate.verificationStatus;
        }
        if (docToUpdate.docType === tableData[index].docType) {
          delete docToUpdate.docType;
        }
        return [...acc, docToUpdate];
      }, []);

      (
        this.isConfidential
        ? this.updateInvestorConfidentialDocuments({ investorId: this.urlParams.investorId, documents: documentsData })
        : this.updateInvestorDocuments({ investorId: this.urlParams.investorId, documents: documentsData })
      )
      .then(this.refreshTable)
      .finally(this.changeModeProxy);
    },
    getDocumentTypesList() {
      const { investorType } = this.getGeneralInfo;
      let res = this.categories.documentTypeIndividual;

      if (investorType === 'entity') {
        res = this.isConfidential
          ? this.categories.entityConfidentialDocumentTypes
          : this.categories.entityDocumentTypes;
      }

      return res.reduce((acc, itm) => ([...acc, { text: itm.text, value: itm.value.docType }]), []);
    },
    getDocTypeName(docCategory, docType) {
      const { investorType } = this.getGeneralInfo;
      let res = this.categories.documentTypeIndividual;

      if (investorType === 'entity') {
        res = this.isConfidential
          ? this.categories.entityConfidentialDocumentTypes
          : this.categories.entityDocumentTypes;
      }

      return this.findType(res, docCategory, docType);
    },
    findType(types, category, type) {
      const found = types.find(({ value }) => value.docCategory === category && value.docType === type);
      if (!found) return type;
      return found.text;
    },
  },
};
</script>
